@import "@/assets/scss/style.scss";

.wrapperTopBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;

    @media (max-width: 560px) {
        flex-direction: column;
    }

    .title {
        color: $grey-9;
        @media (max-width: 560px) {
            margin-bottom: 1.5rem;
        }
    }

    .expandIcon {
        color: $light-grey;
        margin-right: 16px;
    }
}

.shellActions {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    & > * {
        margin-right: 4px;

        &:last-child {
            margin-right: 0;
        }
    }

    .cancelButton {
        color: $grey-9;
    }

    .genericButton {
        line-height: 1;
    }
}

.title-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 24px;
}
